<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗コード</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="tel"
                                  v-number
                                  name="shopCode"
                                  v-model="searchForm.shopCode"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength="45"
                                  name="name"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>企業名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength="45"
                                  name="company"
                                  v-model="searchForm.company"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="attendStoreGroupCount">
                        <FormRow>
                          <template v-slot:label>ジャンル</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="genreId"
                                  v-model="searchForm.genreId"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in genres"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col" v-if="!attendStoreGroupCount">
                        <FormRow>
                          <template v-slot:label>ジャンル</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="genreId"
                                  v-model="searchForm.genreId"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in genres"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="attendStoreGroupCount">
                        <FormRow>
                          <template v-slot:label>イベントグループ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="group"
                                  v-model="searchForm.group"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in attendStoreGroup.list"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗グループ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="groupId"
                                  v-model="searchForm.groupId"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in groupList"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>支払サイクル</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="paymentCycle"
                                  v-model="searchForm.paymentCycle"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in eventPaymentCycles"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>ステータス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="status"
                                  v-model="searchForm.status"
                                >
                                  <option
                                    v-for="item in shopStatusList"
                                    :key="item.id"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>参加店舗数</template>
                  <template v-slot:num>{{ attendStoreTotal | comma }}</template>
                  <template v-slot:sup>件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :storeModule="storeModule"
                    :dataTable="dataTable"
                    :count="attendStoreCount"
                    :labels="labels"
                  ></TableWithCheckbox>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :listLength="attendStoreCount"
                  :searchConditions="searchConditions"
                  :subdomain="subdomain"
                  :modulePath="modulePath"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton :disabled="!attendStoreList.length || isDisable" class="btn btn-black" :handle-submit="hanldeClickDownload" button-text="CSVダウンロード" />
          </li>
          <li class="listGrid-item">
            <button class="btn btn-black" type="button" @click="uploadCsv" :disabled="isImportDisabled">CSVアップロード</button>
            <input
              type="file"
              style="display: none"
              ref="csvInput"
              accept=".csv"
              @change="handleOnCsvFilePicked"
            />
          </li>
          <li class="listGrid-item pos-end">
            <router-link class="btn btn-main" :to="{ name: 'EventStoreAttendRegister' }">新規登録</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
    <ModalQR
      :code="item.shopCode"
      :name="item.name"
      :handleCloseModal="closeModal"
      :isModalQrShown="modal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixins
import pickFileCsv from '../../../mixins/plugin/pickFileCsv';
import nav from '@/mixins/nav/listStoreAttend';
import sort from '@/mixins/plugin/sort';
import modal from '@/mixins/plugin/modal';
import ModalQR from '@/components/ModalQR.vue';
import flatpickr from '@/mixins/plugin/flatpickr';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
import status from '@/mixins/plugin/status';
import listStore from '@/mixins/module/listStore';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload.vue';
import { addThreeDotEndLine } from '@/helpers/formatData';


export default {
  name: 'EventStoreAttendList',
  data: function() {
    return {
      pageName: '参加店舗リスト',
      selectedItem: {},
      modulePath: 'attend/getAttendStores',
      importCsvAction: 'attend/importAttendShopCsv',
      importingItem: 'IMPORTING_ATTEND_STORE',
      importStatusAction: 'shop/getImportStatus',
      searchConditions: {},
      statusForm: {
        note: '',
        status: '',
        shops: [],
        allFlag: false,
      },
      storeModule: 'attend',
      searchFields: ['shopCode', 'name', 'company', 'genreId', 'group', 'groupId', 'paymentCycle', 'status'],
      storedField: 'idRequestExportAttendStore',
      isImportDisabled: false,
      statusTimer: null,
    };
  },
  components: {
    FormRow,
    NumDisplay,
    ModalConfirmDownload,
    PaginationLog,
    TableWithCheckbox,
    ModalQR,
  },
  mixins: [status, nav, flatpickr, modal, sort, search, download, pickFileCsv, listStore],
  computed: {
    ...mapGetters({
      attendStoreList: 'attend/attendStoreList',
      attendStoreTotal: 'attend/attendStoreTotal',
      attendStoreCount: 'attend/attendStoreCount',
      attendStoreGroup: 'attend/attendStoreGroup',
      eventPaymentCycles: 'event/eventPaymentCycles',
      groupList: 'group/groupList',
      shopGroups: 'event/shopGroups',
      genres: 'common/genres',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    attendStoreGroupCount() {
      return this.attendStoreGroup?.count;
    },
    dataTable() {
      return (
        this.attendStoreList &&
        this.attendStoreList.map((item) => {
          const statusName = this.getShopStatusName(item.status);
          return {
            ...item,
            name: addThreeDotEndLine(item.name),
            company: addThreeDotEndLine(item.company),
            group: item.groupName,
            genreName: item.genre?.name || '',
            paymentCycle: item.paymentCycle,
            status: this.getStatusApprovedItem(item),
            buttonLinks: [
              {
                isNotShown: statusName !== '承認済み',
                content: 'QRコードを表示',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.showModal(null, item),
              }
            ]
          };
        })
      );
    },
    labels() {
      return [
        { key: 'shopCode', name: '店舗コード' },
        { key: 'name', name: '店舗名' },
        { key: 'company', name: '企業名' },
        { key: 'genreName', name: 'ジャンル' },
        { key: 'group', name: 'イベントグループ', notShow: !this.attendStoreGroup?.count },
        { key: 'groupShopName', name: '店舗グループ' },
        { key: 'paymentCycle', name: '支払サイクル' },
        { key: 'status', name: 'ステータス', isStatusColumn: true },
        { isButtonColumn: true, name: 'QRコード' },
      ]
    }
  },
  methods: {
    async hanldeClickDownload() {
      this.isDisable = true;
      const result = await this.$store.dispatch('attend/getAttendStoreCSV', {
        ...this.payloadDownloadCsv,
        subdomain: this.subdomain,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
    handleOnCsvFilePicked(event) {
      this.onCsvFilePicked(event, '', null, true, 'csv', 'attend_store_csv');
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('attend/getAttendStoreGroup', { limit: 9999 }),
      this.$store.dispatch('attend/getAttendStoreTotal', {
        subdomain: this.subdomain,
        directory: this.$route.params.directory,
        limit: 1,
        offset: 1,
      }),
      this.$store.dispatch('common/getGenres'),
      this.$store.dispatch('group/getGroupList', { limit: 9999 })
    ]).finally(() => {
      this.$loading.clear(loading);
    });
  },
};
</script>
